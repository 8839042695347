import { DropzoneArea } from 'material-ui-dropzone';
import 'react-toastify/dist/ReactToastify.css';

interface FileUploaderProps {
  handleAttachments: (fieldArray: []) => void;
}

const FileUpload = (props: FileUploaderProps) => {
  const { handleAttachments } = props;
  const handleOnChange = (files: File[]) => {
    let filesArray: any = [];
    files.map(file => {
      let reader = new FileReader();
      reader.onload = e => {
        let uriPath = e.target?.result;
        filesArray.push({
          filename: file.name,
          path: uriPath,
          contentType: file.type,
          contentDisposition: 'attachment',
        });
      };
      return reader.readAsDataURL(file);
    });
    handleAttachments(filesArray);
  };

  return (
    <div>
      <div className='uploader'>
        <DropzoneArea
          showPreviewsInDropzone={true}
          previewGridProps={{ container: { direction: 'column' } }}
          useChipsForPreview
          previewText='Selected files'
          onChange={(files: File[]) => handleOnChange(files)}
          dropzoneText={'Drag and drop a file or click here'}
          filesLimit={10}
          maxFileSize={25000000}
          clearOnUnmount
        />
      </div>
    </div>
  );
};

export default FileUpload;
