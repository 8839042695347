import FileLoader from '../common/LoaderComp';
import { SetStateAction, useState } from 'react';
import { createNewTemplate, getTemplates, sendMail } from '../../apis/email';
import FileUpload from './FileUploader';

interface mailBodyInterface {
  data: {
    from: string;
    to: string;
    cc?: string;
    bcc?: string;
    subject: string;
    text?: string;
    html?: string;
    attachments?: [];
  };
  id: string;
  template?: string;
  locals?: any;
}

const SendMail = ({ id }: { id: string }) => {
  const initialBody = {
    data: {
      from: '',
      to: '',
      cc: '',
      bcc: '',
      subject: '',
      text: '',
      html: '',
    },
    id: '',
  };
  const [mailBody, setMailBody] = useState<mailBodyInterface>(initialBody);
  const [isComplete, setIsComplete] = useState(true);
  const [isTemplate, setIsTemplate] = useState('');
  const [templates, setTemplates] = useState(
    [] as {
      name: string;
      locals: string[];
    }[]
  );
  const [locals, setLocals] = useState([] as string[]);
  const [newTemplate, setNewTemplate] = useState(
    {} as {
      html?: string;
      name: string;
      subject?: string;
      locals?: string[];
    }
  );

  const handleAttachments = (filesArray: []) => {
    let body = { ...mailBody };
    body.data.attachments = filesArray;
    setMailBody(body);
  };

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = mailBody;
    options.data[e.target.id] = e.target.value;
    options.id = id;
    setMailBody(options);
  };

  const handleIsComplete = () => {
    setIsComplete(true);
  };

  const handleMailSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsComplete(false);
    sendMail(mailBody, handleIsComplete);
  };

  const handleIsTemplate = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setIsTemplate(e.target.value);
    let options: any = mailBody;
    if (e.target.value === 'template') {
      options.data['html'] = '';
      options.data['text'] = '';
      getTemplates(setTemplates);
      setLocals([]);
      setMailBody(options);
    } else {
      options.template = '';
    }
  };

  const handleTemplateChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    let options: any = mailBody;
    options.template = e.target.value;
    templates.forEach(template => {
      if (template.name === e.target.value) {
        setLocals(template.locals);
      }
    });
    setMailBody(options);
  };

  const handleLocalsChange = (e: { target: { id: any; value: any } }) => {
    let options: any = mailBody;
    if (!options.locals) {
      options.locals = {};
    }
    options.locals[e.target.id] = e.target.value;
    setMailBody(options);
  };

  const handleCreateTemplate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    createNewTemplate(newTemplate);
    getTemplates(setTemplates);
    setNewTemplate(
      {} as {
        html?: string;
        name: string;
        subject?: string;
        locals?: string[];
      }
    );
  };

  const handleTemplateInputChange = (e: {
    target: { id: any; value: any };
  }) => {
    let options: any = newTemplate;
    let valu = e.target.value;
    if (e.target.id === 'locals') {
      valu = e.target.value
        .split(', ')
        .join(' ')
        .split(',')
        .join(' ')
        .split(' ');
      valu = valu.filter((lo: string | any[]) => {
        return lo.length > 0;
      });
    }
    options[e.target.id] = valu;
    setNewTemplate(options);
  };

  return (
    <div>
      <h3>Enter details of email</h3>
      <input
        type='text'
        id='from'
        placeholder='From*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='to'
        placeholder='To*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='cc'
        placeholder='CC'
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='bcc'
        placeholder='BCC'
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='subject'
        placeholder='Subject'
        required
        onChange={handleInputChange}
      />
      <label>
        Select email type:
        <select onChange={handleIsTemplate}>
          <option value='' hidden>
            Choose here
          </option>
          <option value='template'>Template</option>
          <option value='no template'>No Template</option>
        </select>
      </label>
      {isTemplate === 'template' ? (
        <>
          <label>
            Select Template:
            <select onChange={handleTemplateChange}>
              <option value='' hidden>
                Choose here
              </option>
              {templates.map(template => {
                return (
                  <option value={template.name} key={template.name}>
                    {template.name}
                  </option>
                );
              })}
            </select>
          </label>
          {locals.map(local => {
            return (
              <input
                type='text'
                id={local}
                placeholder={local}
                key={local}
                onChange={handleLocalsChange}
              />
            );
          })}
          {!locals.length ? (
            <div style={{ maxWidth: '100%' }}>
              <h4>OR Create New Template: </h4>
              <input
                type='text'
                id='name'
                placeholder='Name'
                onChange={handleTemplateInputChange}
              />
              <textarea
                id='html'
                name='html'
                placeholder='HTML'
                value={newTemplate.html}
                style={{ maxWidth: '100%', resize: 'none' }}
                onChange={handleTemplateInputChange}
              />
              <input
                type='text'
                id='subject'
                placeholder='Subject'
                onChange={handleTemplateInputChange}
              />
              <input
                type='text'
                id='locals'
                placeholder='Locals'
                required
                onChange={handleTemplateInputChange}
              />
              <button className='submitButton' onClick={handleCreateTemplate}>
                Create Template
              </button>
            </div>
          ) : null}
        </>
      ) : null}
      {isTemplate === 'no template' ? (
        <div>
          <input
            type='text'
            id='text'
            placeholder='Text'
            onChange={handleInputChange}
          />
          <input
            type='text'
            id='html'
            placeholder='HTML'
            onChange={handleInputChange}
          />
        </div>
      ) : null}
      <FileUpload handleAttachments={handleAttachments} />
      {!isComplete ? <FileLoader /> : null}
      <button className='submitButton' onClick={handleMailSend}>
        Send Mail
      </button>
    </div>
  );
};

export default SendMail;
