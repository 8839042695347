import { useState } from 'react';
import { sendSMS } from '../../apis/sms';

interface twilioConfig {
  from: string;
  twilioAccountSid: string;
  twilioAuthToken: string;
  message: string;
}

const TwilioConfig = () => {
  const initialConfig = {
    type: 'twilio',
    options: {
      from: '',
      twilioAccountSid: '',
      twilioAuthToken: '',
      message: '',
    },
  };

  const [config, setConfig] = useState<{
    type: string;
    options: twilioConfig;
  }>(initialConfig);

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = config.options;
    options[e.target.id] = e.target.value;
    setConfig({
      type: 'twilio',
      options,
    });
  };

  const handleSMSSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendSMS(config);
  };

  return (
    <div>
      <input
        type='text'
        id='twilioAccountSid'
        placeholder='Twilio Account Sid*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='twilioAuthToken'
        placeholder='Twilio Auth Token*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='from'
        placeholder='from*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='to'
        placeholder='to*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='message'
        placeholder='Message*'
        required
        onChange={handleInputChange}
      />
      <button className='submitButton' onClick={handleSMSSend}>
        Send SMS
      </button>
    </div>
  );
};

export default TwilioConfig;
