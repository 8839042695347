import React, { useEffect } from 'react';
import IPage from '../interfaces/page';
import logging from '../config/logging';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Home: React.FunctionComponent<IPage & RouteComponentProps<any>> =
  props => {
    useEffect(() => {
      logging.info(`Loading ${props.name}`);
    }, [props]);

    return (
      <div className='home'>
        <h2>Home</h2>
        <p>Welcome to Starter Kit</p>
      </div>
    );
  };

export default withRouter(Home);
