import axios from "axios"
import config from '../config/config'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const sendNotification = (body: any) => {
  if (!body || !body.options || !body.options.fcmTokens || !body.serverKey || !body.options.title || !body.options.text) {
    toast.error('Please fill all the required fields.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    return;
  }
  axios.post(`${config.baseUrl}/custom-notification`, body)
    .then(response => {
      toast.success('Notification Sent', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    })
    .catch(err => {
      toast.error('Something Went Wrong.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
}