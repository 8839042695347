import Loader from 'react-loader-spinner';

const FileLoader = () => {
  return (
    <div className='loader'>
      <Loader type='Oval' color='#00BFFF' height={60} width={60} />
    </div>
  );
};

export default FileLoader;
