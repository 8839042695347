import axios from "axios"
import config from '../config/config'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const sendSMS = (body: any) => {
  if (!body || (body.type === 'fast2sms' && (!body.options.senderId || !body.options.apiKey || !body.options.fast2smsRoute || !body.options.to || !body.options.message)) || (body.type === 'gupshup' && (!body.options.appId || !body.options.apiKey || !body.options.to || !body.options.message)) || (body.type === 'messageBird' && (!body.options.apiKey || !body.options.from || !body.options.to || !body.options.message)) || (body.type === 'twilio' && (!body.options.twilioAccountSid || !body.options.twilioAuthToken || !body.options.from || !body.options.to || !body.options.message))) {
    toast.error('Please fill all the required fields.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    return;
  }
  axios.post(`${config.baseUrl}/send-sms`, body)
    .then(response => {
      toast.success('SMS Sent', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    })
    .catch(err => {
      toast.error('Something Went Wrong.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
}