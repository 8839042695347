import { useState } from 'react';
import { sendSMS } from '../../apis/sms';

interface messageBirdConfig {
  from: string;
  apiKey: string;
  to: string;
  message: string;
}

const MessageBirdConfig = () => {
  const initialConfig = {
    type: 'messageBird',
    options: {
      from: '',
      apiKey: '',
      to: '',
      message: '',
    },
  };

  const [config, setConfig] = useState<{
    type: string;
    options: messageBirdConfig;
  }>(initialConfig);

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = config.options;
    options[e.target.id] = e.target.value;
    setConfig({
      type: 'messagebird',
      options,
    });
  };

  const handleSMSSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendSMS(config);
  };

  return (
    <div>
      <input
        type='text'
        id='apiKey'
        placeholder='Api Key*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='from'
        placeholder='from*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='to'
        placeholder='to*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='message'
        placeholder='Message*'
        required
        onChange={handleInputChange}
      />
      <button className='submitButton' onClick={handleSMSSend}>
        Send SMS
      </button>
    </div>
  );
};

export default MessageBirdConfig;
