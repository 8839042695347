import axios from "axios"
import config from '../config/config'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const sendConfig = (body: any, setConnection: any, handleId: any) => {
  if (!body || ((body.type === 'smtp' && (!body.options || !body.options.host || !body.options.user || !body.options.pass)) || (body.type === "ses" && (!body.options || !body.options.awsAccessId || !body.options.awsAccessKey || !body.options.apiVersion || !body.options.region)))) {
    toast.error('Please fill all the required fields.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    return;
  }
  axios.post(`${config.baseUrl}/mail-config`, body)
    .then((response: any) => {
      toast.success('Connection Setup!', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      handleId(response.data.id);
      setConnection(true);
    })
    .catch(err => {
      toast.error('Something Went Wrong.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setConnection(false);
    });
}

export const sendMail = (body: any, handleIsComplete: () => void) => {
  if (!body || !body.id || !body.data.from || !body.data.to) {
    toast.error('Please fill all the required fields.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    handleIsComplete()
    return;
  }
  axios.post(`${config.baseUrl}/send-mail`, body)
    .then(response => {
      handleIsComplete()
      toast.success('Mail Sent', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    })
    .catch(err => {
      handleIsComplete()
      toast.error('Something Went Wrong.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
}

export const getTemplates = (setTemplate: any) => {
  axios.get(`${config.baseUrl}/get-templates`)
    .then(response => {
      setTemplate(response.data);
    })
    .catch(error => {
      console.log(error)
    })
}


export const createNewTemplate = (body: any) => {
  if (!body || !body.name || (!body.html && !body.subject)) {
    toast.error('Please fill all the required fields.', {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    return;
  }
  axios.post(`${config.baseUrl}/new-template`, body)
    .then(response => {
      toast.success('Template Created.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    })
    .catch(err => {
      toast.error('Something Went Wrong.', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
}