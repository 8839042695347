import React, { useEffect } from 'react';
import IPage from '../interfaces/page';
import logging from '../config/logging';
import {
  RouteComponentProps,
  withRouter,

} from 'react-router-dom';


const Notification: React.FunctionComponent<IPage & RouteComponentProps<any>> =
  props => {
    useEffect(() => {
      logging.info(`Loading ${props.name}`);
    }, [props]);

    return (
      <div>
        <div className='home'>
          <h2>Home</h2>
          <p>Welcome to notification service</p>
        </div>
      </div>
    );
  };

export default withRouter(Notification);
