import { SetStateAction, useState } from 'react';
import SESConfig from './SESConfig';
import SMTPConfig from './SMTPConfig';

const EmailConfigForm = () => {
  const [type, setType] = useState('');
  const [connection, setConnection] = useState<boolean>(false);
  const [id, setId] = useState('');

  const handleServerType = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setType(e.target.value);
  };

  const connect: {
    setConnection: (connection: boolean) => void;
    connection: any;
  } = {
    connection,
    setConnection,
  };

  const handleId = (id: any) => {
    setId(id);
  };

  return (
    <div>
      <form className='Notification'>
        {!connection ? (
          <label>
            Select email server type:
            <select value={type} onChange={handleServerType}>
              <option value='' hidden>
                Choose here
              </option>
              <option value='smtp'>SMTP</option>
              <option value='ses'>SES</option>
            </select>
          </label>
        ) : null}
        {type === 'smtp' ? (
          <div>
            <SMTPConfig connect={connect} handleId={handleId} id={id} />
          </div>
        ) : null}
        {type === 'ses' ? (
          <div>
            <SESConfig connect={connect} handleId={handleId} id={id} />
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default EmailConfigForm;
