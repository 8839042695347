import { useState } from 'react';
import { sendSMS } from '../../apis/sms';
interface gupshupConfig {
  appId: string;
  apiKey: string;
  to: string;
  message: string;
}

const GupshupConfig = () => {
  const initialConfig = {
    type: 'gupshup',
    options: {
      appId: '',
      apiKey: '',
      to: '',
      message: ''
    },
  };

  const [config, setConfig] = useState<{
    type: string;
    options: gupshupConfig;
  }>(initialConfig);

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = config.options;
    options[e.target.id] = e.target.value;
    setConfig({
      type: 'gupshup',
      options,
    });
  };

  const handleSMSSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendSMS(config);
  };
  return (
    <div>
      <input
        type='text'
        id='appId'
        placeholder='App Id*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='apiKey'
        placeholder='Api Key*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='to'
        placeholder='to*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='message'
        placeholder='Message*'
        required
        onChange={handleInputChange}
      />
      <button className='submitButton' onClick={handleSMSSend}>Send SMS</button>
    </div>
  );
};

export default GupshupConfig;
