import { useState } from 'react';
import { sendConfig } from '../../apis/email';
import SendMail from './SendMail';

interface sesConfig {
  awsAccessId: string;
  awsAccessKey: string;
  apiVersion: string;
  region: string;
}

const SESConfig = ({
  connect,
  handleId,
  id,
}: {
  connect: {
    setConnection: (connection: boolean) => void;
    connection: any;
  };
  handleId: (id: string) => void;
  id: string;
}) => {
  const initialConfig = {
    type: 'ses',
    options: {
      awsAccessId: '',
      awsAccessKey: '',
      apiVersion: '',
      region: '',
    },
  };

  const [config, setConfig] = useState<{
    type: string;
    options: sesConfig;
  }>(initialConfig);

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = config.options;
    options[e.target.id] = e.target.value;
    setConfig({
      type: 'ses',
      options,
    });
  };

  const handleConfigSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendConfig(config, connect.setConnection, handleId);
  };

  return connect.connection ? (
    <SendMail id={id} />
  ) : (
    <div>
      <h3>Enter Configuration for selected server </h3>
      <input
        type='text'
        id='awsAccessId'
        placeholder='AWS Access Id*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='awsAccessKey'
        placeholder='AWS Access Key*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='apiVersion'
        placeholder='API Version*'
        required
        onChange={handleInputChange}
      />
      <input
        type='text'
        id='region'
        placeholder='Region*'
        required
        onChange={handleInputChange}
      />
      <button className='submitButton' onClick={handleConfigSend}>
        Submit Config
      </button>
    </div>
  );
};

export default SESConfig;
