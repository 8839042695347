import IRoute from '../interfaces/route';
import Email from '../pages/email';
import SMS from '../pages/sms';
import Custom from '../pages/custom';
import Home from '../pages/home';
import Notification from '../pages/notification';
import UserManagement from '../pages/user-management';

const routes: IRoute[] = [
  {
    path: '/',
    name: 'Home Page',
    component: Home,
    exact: true
  },
  {
    path: '/notification',
    name: 'Notification Page',
    component: Notification,
    exact: true
  },
  {
    path: '/email',
    name: 'Email Page',
    component: Email,
    exact: true
  },
  {
    path: '/sms',
    name: 'SMS Page',
    component: SMS,
    exact: true
  },
  {
    path: '/custom',
    name: 'Custom Page',
    component: Custom,
    exact: true
  },
  {
    path: '/user-management',
    name: 'Usaer Management Page',
    component: UserManagement,
    exact: true
  },
]

export default routes;