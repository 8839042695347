import React, { useEffect } from 'react';
import IPage from '../interfaces/page';
import logging from '../config/logging';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CustomNotificationForm from '../components/custom/CustomNotificationForm';

const Custom: React.FunctionComponent<IPage & RouteComponentProps<any>> =
  props => {
    useEffect(() => {
      logging.info(`Loading ${props.name}`);
    }, [props]);

    return (
      <div>
        <CustomNotificationForm />
      </div>
    );
  };

export default withRouter(Custom);
