import { SetStateAction, useState } from 'react';
import Fast2SMSConfig from './Fast2SMSConfig';
import GupshupConfig from './GupshupConfig';
import MessageBirdConfig from './MessageBirdConfig';
import TwilioConfig from './TwilioConfig';

const SMSConfigForm = () => {
  const [type, setType] = useState('');

  const handleServerType = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setType(e.target.value);
  };

  return (
    <div>
      <form className='Notification'>
        <label>
          Select SMS server type:
          <select value={type} onChange={handleServerType}>
            <option value='' hidden>
              Choose here
            </option>
            <option value='fast2sms'>Fast2SMS</option>
            <option value='gupshup'>Gupshup</option>
            <option value='messagebird'>Messagebird</option>
            <option value='twilio'>Twilio</option>
          </select>
        </label>
        {type === 'fast2sms' ? (
          <div>
            <Fast2SMSConfig />
          </div>
        ) : null}
        {type === 'gupshup' ? (
          <div>
            <GupshupConfig />
          </div>
        ) : null}
        {type === 'messagebird' ? (
          <div>
            <MessageBirdConfig />
          </div>
        ) : null}
        {type === 'twilio' ? (
          <div>
            <TwilioConfig />
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default SMSConfigForm;
