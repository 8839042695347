import React, { useEffect } from 'react';
import IPage from '../interfaces/page';
import logging from '../config/logging';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EmailConfigForm from '../components/email/EmailConfigForm';

const Email: React.FunctionComponent<IPage & RouteComponentProps<any>> =
  props => {
    useEffect(() => {
      logging.info(`Loading ${props.name}`);
    }, [props]);

    return (
      <div>
        <EmailConfigForm />
      </div>
    );
  };

export default withRouter(Email);
