import React, { useEffect } from 'react';
import IPage from '../interfaces/page';
import logging from '../config/logging';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const UserManagement: React.FunctionComponent<IPage & RouteComponentProps<any>> =
  props => {
    useEffect(() => {
      logging.info(`Loading ${props.name}`);
    }, [props]);

   

    return (
      <div className='home'>
        <h2>Home</h2>
        <p>Welcome to User Management</p>
      </div>
    );
  };

export default withRouter(UserManagement);


// import { useState } from 'react';
// import { sendConfig } from '../../apis/email';
// import SendMail from './SendMail';

// interface smtpConfig {
//   user: string;
//   pass: string;
//   host: string;
// }

// const SMTPConfig = ({
//   connect,
//   handleId,
//   id,
// }: {
//   connect: {
//     setConnection: (connection: boolean) => void;
//     connection: any;
//   };
//   handleId: (id: string) => void;
//   id: string;
// }) => {
//   const initialConfig = {
//     type: 'smtp',
//     options: {
//       host: '',
//       pass: '',
//       user: '',
//     },
//   };

//   const [config, setConfig] = useState<{
//     type: string;
//     options: smtpConfig;
//   }>(initialConfig);

//   const handleInputChange = (e: { target: { id: any; value: any } }) => {
//     let options: any = config.options;
//     options[e.target.id] = e.target.value;
//     setConfig({
//       type: 'smtp',
//       options,
//     });
//   };

//   const handleConfigSend = (e: { preventDefault: () => void }) => {
//     e.preventDefault();
//     sendConfig(config, connect.setConnection, handleId);
//   };

//   return connect.connection ? (
//     <SendMail id={id} />
//   ) : (
//     <div>
//       <h3>Enter Configuration for selected server </h3>
//       <input
//         type='text'
//         id='host'
//         placeholder='Host*'
//         onChange={handleInputChange}
//       />
//       <input
//         type='text'
//         id='user'
//         placeholder='Username*'
//         onChange={handleInputChange}
//       />
//       <input
//         type='password'
//         id='pass'
//         placeholder='Password*'
//         onChange={handleInputChange}
//       />
//       <button className='submitButton' onClick={handleConfigSend}>
//         Submit Config
//       </button>
//     </div>
//   );
// };

// export default SMTPConfig;
