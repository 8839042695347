import { useState } from 'react';
import { sendNotification } from '../../apis/custom';

interface customConfig {
  fcmTokens: string;
  serverKey: string;
  title: string;
  text: string;
}

const CustomNotificationForm = () => {
  const initialConfig = {
    options: {
      fcmTokens: '',
      serverKey: '',
      title: '',
      text: '',
    },
  };

  const [config, setConfig] = useState<{
    options: customConfig;
  }>(initialConfig);

  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    let options: any = config.options;
    options[e.target.id] = e.target.value;
    setConfig({
      options,
    });
  };

  const handleNotificationSend = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendNotification(config);
  };

  return (
    <form className='Notification'>
      <div>
        <input
          type='text'
          id='fcmTokens'
          placeholder='Fcm Tokens*'
          onChange={handleInputChange}
        />
        <input
          type='text'
          id='serverKey'
          placeholder='Server Key*'
          onChange={handleInputChange}
        />
        <input
          type='text'
          id='title'
          placeholder='Title*'
          onChange={handleInputChange}
        />
        <input
          type='text'
          id='text'
          placeholder='Text*'
          onChange={handleInputChange}
        />
        <button className='submitButton' onClick={handleNotificationSend}>
          Send Notification
        </button>
      </div>
    </form>
  );
};

export default CustomNotificationForm;
