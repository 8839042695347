import { useEffect } from 'react';
import './App.css';
import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  Link,
} from 'react-router-dom';
import logging from './config/logging';
import routes from './config/routes';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

const App = () => {
  useEffect(() => {
    logging.info('Loading application.');
  }, []);

  return (
    <div>
      <BrowserRouter>
        <nav style={{ textAlign: 'center' }}>
          <Link className='navButton' to='/'>
            Home
          </Link>
          <Link className='navButton' to='/email'>
            Email
          </Link>
          <Link className='navButton' to='/sms'>
            SMS
          </Link>
          <Link className='navButton' to='/custom'>
            FCM
          </Link>
          <Link className='navButton' to='/user-management'>
            User Management
          </Link>
        </nav>
        <ToastContainer theme={'colored'} />
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                render={(props: RouteComponentProps<any>) => (
                  <route.component
                    name={route.name}
                    {...props}
                    {...route.props}
                  />
                )}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
